import AccountsPayable from '@assets/Icons/AllApps/AccountsPayable.svg';
import ActivityTracking from '@assets/Icons/AllApps/ActivityTracking.svg';
import Analytics from '@assets/Icons/AllApps/Analytics.svg';
import ARVision from '@assets/Icons/AllApps/ARVision.svg';
import ASNAuditTrail from '@assets/Icons/AllApps/ASNAuditTrail.svg';
import AssetVisibility from '@assets/Icons/AllApps/AssetVisibility.svg';
import AutoStore from '@assets/Icons/AllApps/AutoStore.svg';
import BarcodeScanning from '@assets/Icons/AllApps/BarcodeScanning.svg';
import Claims from '@assets/Icons/AllApps/Claims.svg';
import ConveyorLabelScan from '@assets/Icons/AllApps/ConveyorLabelScan.svg';
import Customs from '@assets/Icons/AllApps/Customs.svg';
import DamageDetection from '@assets/Icons/AllApps/DamageDetection.svg';
import DefectDetection from '@assets/Icons/AllApps/DefectDetection.svg';
import Dematic from '@assets/Icons/AllApps/Dematic.svg';
import DockDoorVisibility from '@assets/Icons/AllApps/DockDoorVisibility.svg';
import DocumentDigitalization from '@assets/Icons/AllApps/DocumentDigitalization.svg';
import Drayage from '@assets/Icons/AllApps/Drayage.svg';
import EPOD from '@assets/Icons/AllApps/EPOD.svg';
import Factory from '@assets/Icons/AllApps/Factory.svg';
import FactoryProductSortation from '@assets/Icons/AllApps/FactoryProductSortation.svg';
import FMCSACompliance from '@assets/Icons/AllApps/FMCSACompliance.svg';
import FreightForwarding from '@assets/Icons/AllApps/FreightForwarding.svg';
import IndustrialOmniverse from '@assets/Icons/AllApps/IndustrialOmniverse.svg';
import Insights from '@assets/Icons/AllApps/Insights.svg';
import IntelligentFactories from '@assets/Icons/AllApps/IntelligentFactories.svg';
import InventoryVisibility from '@assets/Icons/AllApps/InventoryVisibility.svg';
import ItemDimensioning from '@assets/Icons/AllApps/ItemDimensioning.svg';
import LashingUnlashing from '@assets/Icons/AllApps/LashingUnlashing.svg';
import LastMileDelivery from '@assets/Icons/AllApps/LastMileDelivery.svg';
import Maritime from '@assets/Icons/AllApps/Maritime.svg';
import OrderCartAudit from '@assets/Icons/AllApps/OrderCartAudit.svg';
import PerimeterSurveillance from '@assets/Icons/AllApps/PerimeterSurveillance.svg';
import Port from '@assets/Icons/AllApps/Port.svg';
import PortAssetTracking from '@assets/Icons/AllApps/PortAssetTracking.svg';
import PortAutonomousGates from '@assets/Icons/AllApps/PortAutonomousGates.svg';
import PredictiveDockDoor from '@assets/Icons/AllApps/PredictiveDockDoor.svg';
import PreventiveMaintenance from '@assets/Icons/AllApps/PreventiveMaintenance.svg';
import Procurement from '@assets/Icons/AllApps/Procurement.svg';
import ProductScan from '@assets/Icons/AllApps/ProductScan.svg';
import QualityControl from '@assets/Icons/AllApps/QualityControl.svg';
import RealWear from '@assets/Icons/AllApps/Realwear.svg';
import Reports from '@assets/Icons/AllApps/Reports.svg';
import ReverseLogistics from '@assets/Icons/AllApps/ReverseLogistics.svg';
import SKURecognition from '@assets/Icons/AllApps/SKURecognition.svg';
import SmartLabelScan from '@assets/Icons/AllApps/SmartLabelScan.svg';
import SmartPorts from '@assets/Icons/AllApps/SmartPorts.svg';
// import SmartWarehousing from '@assets/Icons/AllApps/SmartWarehousing.svg';
import SmartYards from '@assets/Icons/AllApps/SmartYards.svg';
import SyntheticAssets from '@assets/Icons/AllApps/SyntheticAssets.svg';
import TeamViewer from '@assets/Icons/AllApps/TeamViewer.svg';
import ThirdPartyIntegrations from '@assets/Icons/AllApps/ThirdPartyIntegrations.svg';
import TrafficManagement from '@assets/Icons/AllApps/TrafficManagement.svg';
import VisionPicking from '@assets/Icons/AllApps/VisionPicking.svg';
import Warehouse from '@assets/Icons/AllApps/Warehouse.svg';
import WarehouseAssetTracking from '@assets/Icons/AllApps/WarehouseAssetTracking.svg';
import WarehouseProductSortation from '@assets/Icons/AllApps/WarehouseProductSortation.svg';
import WorkerSafety from '@assets/Icons/AllApps/WorkerSafety.svg';
import Yard from '@assets/Icons/AllApps/Yard.svg';
import YardAutonomousGates from '@assets/Icons/AllApps/YardAutonomousGates.svg';
import YardCheck from '@assets/Icons/AllApps/YardCheck.svg';
import Zebra from '@assets/Icons/AllApps/Zebra.svg';
import WarehourseIntelligence from '@assets/Icons/ProductCodeScan/WarehouseIntelligence.svg';
import { AppGroupNames, AppNames } from 'src/global/constants';
import { AppGroup, WickedApi } from 'src/global/types';

export const getSelectedAppGroup = (tab: string, appGroups: AppGroup[]) => {
  let currentAppGroups;

  switch (tab) {
    case 'allApps': {
      currentAppGroups = appGroups;
      break;
    }

    case 'warehouseIntelligence': {
      currentAppGroups = appGroups.filter(
        (group) => group.id === 'warehouseIntelligence'
      );
      break;
    }

    case 'smartYards': {
      currentAppGroups = appGroups.filter((group) => group.id === 'smartYards');
      break;
    }

    case 'smartPorts': {
      currentAppGroups = appGroups.filter((group) => group.id === 'smartPorts');
      break;
    }

    case 'intelligentFactories': {
      currentAppGroups = appGroups.filter(
        (group) => group.id === 'intelligentFactories'
      );
      break;
    }

    case 'documentDigitalization': {
      currentAppGroups = appGroups.filter(
        (group) => group.id === 'documentDigitalization'
      );
      break;
    }

    case 'thirdPartyIntegrations': {
      currentAppGroups = appGroups.filter(
        (group) => group.id === 'thirdPartyIntegrations'
      );
      break;
    }

    case 'insights': {
      currentAppGroups = appGroups.filter((group) => group.id === 'insights');
      break;
    }

    case 'industrialOmniverse': {
      currentAppGroups = appGroups.filter(
        (group) => group.id === 'industrialOmniverse'
      );
      break;
    }

    default: {
      currentAppGroups = appGroups;
      break;
    }
  }

  return currentAppGroups;
};

export const getAppGroups = (
  subscribedDashboardApps: WickedApi[]
): AppGroup[] => {
  return [
    {
      id: 'warehouseIntelligence', // 'warehouseIntelligence'
      title: AppGroupNames.smartWarehousing,
      icon: <WarehourseIntelligence />,
      appsDetails: [
        {
          id: 'barcodeScanning',
          name: AppNames.barcodeScanning,
          icon: <BarcodeScanning />,
          isSubscribed: subscribedDashboardApps.includes('barcodeScanning'),
        },
        {
          id: 'smartLabelScan',
          name: AppNames.smartLabelScan,
          icon: <SmartLabelScan />,
          isSubscribed: subscribedDashboardApps.includes('smartLabelScan'),
        },
        {
          id: 'conveyorLabelScan',
          name: AppNames.conveyorLabelScan,
          icon: <ConveyorLabelScan />,
          isSubscribed: subscribedDashboardApps.includes('conveyorLabelScan'),
        },
        {
          id: 'visionPicking',
          name: AppNames.visionPicking,
          icon: <VisionPicking />,
          isSubscribed: subscribedDashboardApps.includes('visionPicking'),
        },
        {
          id: 'inventoryVisibility',
          name: AppNames.inventoryVisibility,
          icon: <InventoryVisibility />,
          isSubscribed: subscribedDashboardApps.includes('inventoryVisibility'),
        },
        {
          id: 'dockDoorVisibility',
          name: AppNames.dockDoorVisibility,
          icon: <DockDoorVisibility />,
          isSubscribed: subscribedDashboardApps.includes('dockDoorVisibility'),
        },
        {
          id: 'asnAuditTrail',
          name: AppNames.asnAuditTrail,
          icon: <ASNAuditTrail />,
          isSubscribed: subscribedDashboardApps.includes('asnAuditTrail'),
        },
        {
          id: 'orderCartAudit',
          name: AppNames.orderCartAudit,
          icon: <OrderCartAudit />,
          isSubscribed: subscribedDashboardApps.includes('orderCartAudit'),
        },
        {
          id: 'activityTracking',
          name: AppNames.activityTracking,
          icon: <ActivityTracking />,
          isSubscribed: subscribedDashboardApps.includes('activityTracking'),
        },
        {
          id: 'damageDetection',
          name: AppNames.damageDetection,
          icon: <DamageDetection />,
          isSubscribed: subscribedDashboardApps.includes('damageDetection'),
        },
        {
          id: 'warehouseProductSortation',
          name: AppNames.warehouseProductSortation,
          icon: <WarehouseProductSortation />,
          isSubscribed: subscribedDashboardApps.includes(
            'warehouseProductSortation'
          ),
        },
        {
          id: 'reverseLogistics',
          name: AppNames.reverseLogistics,
          icon: <ReverseLogistics />,
          isSubscribed: subscribedDashboardApps.includes('reverseLogistics'),
        },
        {
          id: 'warehouseAssetTracking',
          name: AppNames.warehouseAssetTracking,
          icon: <WarehouseAssetTracking />,
          isSubscribed: subscribedDashboardApps.includes(
            'warehouseAssetTracking'
          ),
        },
        {
          id: 'itemDimensioning',
          name: AppNames.itemDimensioning,
          icon: <ItemDimensioning />,
          isSubscribed: subscribedDashboardApps.includes('itemDimensioning'),
        },
        {
          id: 'workerSafety',
          name: AppNames.workerSafety,
          icon: <WorkerSafety />,
          isSubscribed: subscribedDashboardApps.includes('workerSafety'),
        },
        {
          id: 'productScan',
          name: AppNames.productScan,
          icon: <ProductScan />,
          isSubscribed: subscribedDashboardApps.includes('portal-api'), // 'productScan'
        },
      ],
    },
    {
      id: 'smartYards',
      title: AppGroupNames.smartYards,
      icon: <SmartYards />,
      appsDetails: [
        {
          id: 'lastMileDelivery',
          name: AppNames.lastMileDelivery,
          icon: <LastMileDelivery />,
          isSubscribed: subscribedDashboardApps.includes('portal-api'), // 'lastMileDelivery'
        },
        {
          id: 'yardAutonomousGates',
          name: AppNames.yardAutonomousGates,
          icon: <YardAutonomousGates />,
          isSubscribed: subscribedDashboardApps.includes('yardAutonomousGates'),
        },
        {
          id: 'assetVisibility',
          name: AppNames.assetVisibility,
          icon: <AssetVisibility />,
          isSubscribed: subscribedDashboardApps.includes('assetVisibility'),
        },
        {
          id: 'ePOD',
          name: AppNames.ePOD,
          icon: <EPOD />,
          isSubscribed: subscribedDashboardApps.includes('ePOD'),
        },
        {
          id: 'predictiveDockDoor',
          name: AppNames.predictiveDockDoor,
          icon: <PredictiveDockDoor />,
          isSubscribed: subscribedDashboardApps.includes('predictiveDockDoor'),
        },
        {
          id: 'trafficManagement',
          name: AppNames.trafficManagement,
          icon: <TrafficManagement />,
          isSubscribed: subscribedDashboardApps.includes('trafficManagement'),
        },
        {
          id: 'fmcsaCompliance',
          name: AppNames.fmcsaCompliance,
          icon: <FMCSACompliance />,
          isSubscribed: subscribedDashboardApps.includes('fmcsaCompliance'),
        },
        {
          id: 'perimeterSurveillance',
          name: AppNames.perimeterSurveillance,
          icon: <PerimeterSurveillance />,
          isSubscribed: subscribedDashboardApps.includes(
            'perimeterSurveillance'
          ),
        },
        {
          id: 'yardCheck',
          name: AppNames.yardCheck,
          icon: <YardCheck />,
          isSubscribed: subscribedDashboardApps.includes('yardCheck'),
        },
      ],
    },
    {
      id: 'intelligentFactories',
      title: AppGroupNames.intelligentFactories,
      icon: <IntelligentFactories />,
      appsDetails: [
        {
          id: 'skuRecognition',
          name: AppNames.skuRecognition,
          icon: <SKURecognition />,
          isSubscribed: subscribedDashboardApps.includes('skuRecognition'),
        },
        {
          id: 'factoryProductSortation',
          name: AppNames.factoryProductSortation,
          icon: <FactoryProductSortation />,
          isSubscribed: subscribedDashboardApps.includes(
            'factoryProductSortation'
          ),
        },
        {
          id: 'arVision',
          name: AppNames.arVision,
          icon: <ARVision />,
          isSubscribed: subscribedDashboardApps.includes('arVision'),
        },
        {
          id: 'defectDetection',
          name: AppNames.defectDetection,
          icon: <DefectDetection />,
          isSubscribed: subscribedDashboardApps.includes('defectDetection'),
        },
        {
          id: 'qualityControl',
          name: AppNames.qualityControl,
          icon: <QualityControl />,
          isSubscribed: subscribedDashboardApps.includes('qualityControl'),
        },
      ],
    },
    {
      id: 'smartPorts',
      title: AppGroupNames.smartPorts,
      icon: <SmartPorts />,
      appsDetails: [
        {
          id: 'portAutonomousGates',
          name: AppNames.portAutonomousGates,
          icon: <PortAutonomousGates />,
          isSubscribed: subscribedDashboardApps.includes('portAutonomousGates'),
        },
        {
          id: 'preventiveMaintenance',
          name: AppNames.preventiveMaintenance,
          icon: <PreventiveMaintenance />,
          isSubscribed: subscribedDashboardApps.includes(
            'preventiveMaintenance'
          ),
        },
        {
          id: 'portAssetTracking',
          name: AppNames.portAssetTracking,
          icon: <PortAssetTracking />,
          isSubscribed: subscribedDashboardApps.includes('portAssetTracking'),
        },
        {
          id: 'lashingUnlashing',
          name: AppNames.lashingUnlashing,
          icon: <LashingUnlashing />,
          isSubscribed: subscribedDashboardApps.includes('lashingUnlashing'),
        },
      ],
    },
    {
      id: 'thirdPartyIntegrations',
      title: AppGroupNames.thirdPartyIntegrations,
      icon: <ThirdPartyIntegrations />,
      appsDetails: [
        {
          id: 'realWear',
          name: AppNames.realWear,
          icon: <RealWear />,
          isSubscribed: subscribedDashboardApps.includes('realWear'),
        },
        {
          id: 'teamViewer',
          name: AppNames.teamViewer,
          icon: <TeamViewer />,
          isSubscribed: subscribedDashboardApps.includes('teamViewer'),
        },
        {
          id: 'dematic',
          name: AppNames.dematic,
          icon: <Dematic />,
          isSubscribed: subscribedDashboardApps.includes('dematic'),
        },
        {
          id: 'zebra',
          name: AppNames.zebra,
          icon: <Zebra />,
          isSubscribed: subscribedDashboardApps.includes('zebra'),
        },
        {
          id: 'autoStore',
          name: AppNames.autoStore,
          icon: <AutoStore />,
          isSubscribed: subscribedDashboardApps.includes('autoStore'),
        },
      ],
    },
    {
      id: 'documentDigitalization',
      title: AppGroupNames.documentDigitalization,
      icon: <DocumentDigitalization />,
      appsDetails: [
        // {
        //   id: 'drayage',
        //   name: AppNames.drayage,
        //   icon: <DeliveryOrder />,
        //   isSubscribed: subscribedDashboardApps.includes('drayage'),
        // },
        {
          id: 'deliveryOrder',
          name: AppNames.deliveryOrder,
          icon: <Drayage />,
          isSubscribed: subscribedDashboardApps.includes('deliveryOrder'),
        },
        {
          id: 'freightForwarding',
          name: AppNames.freightForwarding,
          icon: <FreightForwarding />,
          isSubscribed: subscribedDashboardApps.includes('freightForwarding'),
        },
        {
          id: 'otr',
          name: AppNames.otr,
          icon: <FreightForwarding />,
          isSubscribed: subscribedDashboardApps.includes('otr'),
        },
        {
          id: 'customs',
          name: AppNames.customs,
          icon: <Customs />,
          isSubscribed: subscribedDashboardApps.includes('customs'),
        },
        {
          id: 'maritime',
          name: AppNames.maritime,
          icon: <Maritime />,
          isSubscribed: subscribedDashboardApps.includes('maritime'),
        },
        {
          id: 'procurement',
          name: AppNames.procurement,
          icon: <Procurement />,
          isSubscribed: subscribedDashboardApps.includes('procurement'),
        },
        {
          id: 'accountsPayable',
          name: AppNames.accountsPayable,
          icon: <AccountsPayable />,
          isSubscribed: subscribedDashboardApps.includes('accountsPayable'),
        },
        {
          id: 'claims',
          name: AppNames.claims,
          icon: <Claims />,
          isSubscribed: subscribedDashboardApps.includes('claims'),
        },
      ],
    },
    {
      id: 'industrialOmniverse',
      title: AppGroupNames.industrialOmniverse,
      icon: <IndustrialOmniverse />,
      appsDetails: [
        {
          id: 'warehouse',
          name: AppNames.warehouse,
          icon: <Warehouse />,
          isSubscribed: subscribedDashboardApps.includes('warehouse'),
        },
        {
          id: 'yard',
          name: AppNames.yard,
          icon: <Yard />,
          isSubscribed: subscribedDashboardApps.includes('yard'),
        },
        {
          id: 'port',
          name: AppNames.port,
          icon: <Port />,
          isSubscribed: subscribedDashboardApps.includes('port'),
        },
        {
          id: 'factory',
          name: AppNames.factory,
          icon: <Factory />,
          isSubscribed: subscribedDashboardApps.includes('factory'),
        },
        {
          id: 'syntheticAssets',
          name: AppNames.syntheticAssets,
          icon: <SyntheticAssets />,
          isSubscribed: subscribedDashboardApps.includes('syntheticAssets'),
        },
      ],
    },
    {
      id: 'insights',
      title: AppGroupNames.insights,
      icon: <Insights />,
      appsDetails: [
        {
          id: 'reports',
          name: AppNames.reports,
          icon: <Reports />,
          isSubscribed: subscribedDashboardApps.includes('reports'),
        },
        {
          id: 'analytics',
          name: AppNames.analytics,
          icon: <Analytics />,
          isSubscribed: subscribedDashboardApps.includes('analytics'),
        },
      ],
    },
  ];
};
